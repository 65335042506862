import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Sorry, that link seems to be broken...like some maps out there 🗺</h1>
    <h2>Some of our pages have moved. Are you looking for any of these?</h2>
    <ul>
      <li>
        <h3><a href="/">our state by state redistricting reform guide</a></h3>
      </li>
      <li>
        <h3><a href="/reforms/mi">our Michigan reforms content</a></h3>
      </li>
      <li>
        <h3><a href="/reforms/va">our Virginia reforms content</a></h3>
      </li>
    </ul>
  </Layout>
)

export default NotFoundPage
